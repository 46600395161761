import { ReactSession } from 'react-client-session';

export function setAccessToken(accessToken) {
    ReactSession.setStoreType("localStorage");
    ReactSession.set('access_token', accessToken);
}

export function retrieveAccessToken() {
    ReactSession.setStoreType("localStorage");
    return ReactSession.get('access_token');
}

export function deleteAccessToken() {
    ReactSession.setStoreType("localStorage");
    return ReactSession.remove('access_token');
}

export function setUserId(userId) {
    ReactSession.setStoreType("localStorage");
    return ReactSession.set('user_id', userId);
}

export function retrieveUserId() {
    ReactSession.setStoreType("localStorage");
    return ReactSession.get('user_id');
}

export function deleteUserId() {
    ReactSession.setStoreType("localStorage");
    return ReactSession.remove('user_id');
}

export function deleteStorage() {
    deleteAccessToken();
    deleteUserId();
}