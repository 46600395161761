import { makePostRequest, makeRequest } from "./makeRequest";

export const Requests = {
    currentUserProfile: '/me',
    currentUserLikedSongs: '/me/tracks',
    currentUserPlaylists: '/me/playlists',
    playlistItems: (playlistId) => `/playlists/${playlistId}/tracks`,
    addItemsToPlaylist: (playlistId) => `/playlists/${playlistId}/tracks`,
    createPlaylist: (userId) => `/users/${userId}/playlists`,
}

export async function retrieveUserInfo(accessToken) {
    const result = await makeRequest(Requests.currentUserProfile, accessToken);

    if (result) {
        return result.data;
    }
}

export async function retrievePlaylists(accessToken) {
    const result = await makeRequest(Requests.currentUserPlaylists, accessToken);

    if (result) {
        return result.data;
    }
}

export async function retrievePlaylistItems(playlistId, accessToken) {
    const result = await makeRequest(Requests.playlistItems(playlistId), accessToken);

    if (result) {
        return result.data.items;
    }
}

export async function retrieveLikedSongs(accessToken) {
    const result = await makeRequest(Requests.currentUserLikedSongs, accessToken);

    if (result) {
        return result.data.items;
    }
}

export async function addTrackToPlaylist(trackURIs, playlistId, accessToken) {
    const params = constructParamsFromJSONObj(trackURIs);
    const url = Requests.addItemsToPlaylist(playlistId) + params;
    const result = await makePostRequest(url, accessToken);

    if (result) {
        return result.data.items;
    }
}

export async function createNewPlaylist(userId, dataObj, accessToken) {
    const result = await makePostRequest(Requests.createPlaylist(userId), accessToken, dataObj);

    if (result) {
        return result;
    }
}

function constructParamsFromJSONObj(paramsObj) {
    const keys = Object.keys(paramsObj);
    let params = '';

    for (let i = 0; i < keys.length; i++) {
        if (Array.isArray(paramsObj[keys[i]])) {
            params = params + '?' + keys[i] + '=' + encodeURIComponent(paramsObj[keys[i]].join(','));
        }
    }

    return params;
}

export const apiUrl = 'https://api.spotify.com/v1';