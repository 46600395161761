import { Card, CardContent, CardHeader } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Song from '../Song/Song'
import { makeRequest } from '../Utils/makeRequest'
import { retrieveLikedSongs } from '../Utils/RequestUtils'
import './Liked.css'

const Liked = ({ accessToken }) => {
    const [likedSongs, setLikedSongs] = useState([])

    useEffect(() => {
        displayLikedSongs();
    }, [])

    async function displayLikedSongs() {
        const likedSongs = await retrieveLikedSongs(accessToken);
        // console.log(likedSongs);
        setLikedSongs(likedSongs);
    }

    return (
        <Card className='liked-cont' sx={{ p: 1, marginBottom: 3 }}>
            <CardHeader className='liked-header' sx={{ p: 1 }} title="Liked Songs" />
            <CardContent className='liked-card' sx={{ backgroundColor: '#181818' }}>
                {
                    likedSongs &&
                    <div>
                        {
                            likedSongs.map((el, key) => (
                                <Song songData={el} />
                            ))
                        }
                    </div>
                }
            </CardContent>
        </Card>
    )
}

export default Liked