
const Note = () => {
    return (
        <svg width="16px" height="16px" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.333 4.333H13.333M15.333 4.333H13.333M13.333 4.333V2.333M13.333 4.333V6.333" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4 10.667V3.333L9.333 2.667" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10 9.333V6.667" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M8 12.667H8.667C9.403 12.667 10 12.07 10 11.333V9.333H8C7.264 9.333 6.667 9.93 6.667 10.667V11.333C6.667 12.07 7.264 12.667 8 12.667Z" fill="currentColor" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M2 14H2.667C3.403 14 4 13.403 4 12.667V10.667H2C1.264 10.667 0.667 11.264 0.667 12V12.667C0.667 13.403 1.264 14 2 14Z" stroke="currentColor" fill="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default Note