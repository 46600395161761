import { AxiosError } from "axios";
import { deleteAccessToken, deleteStorage } from "./StorageUtils";

const ACCESS_TOKEN_EXPIRED = 'The access token expired';
const INVALID_ACCESS_TOKEN = 'Invalid access token';

export function handleError(error) {
    if (error instanceof AxiosError) {
        switch (error.response.data.error.message) {
            case ACCESS_TOKEN_EXPIRED:
                handleTokenExpired();
                break;
            case INVALID_ACCESS_TOKEN:
                handleInvalidAccessToken();
                break;
            default:
                console.log(`Unknown error ${error}`);
                break;
        }
    }
}

function handleTokenExpired() {
    window.location.href = '/newtoken';
    deleteStorage();
}

function handleInvalidAccessToken() {
    window.location.href = '/newtoken';
    deleteStorage();
}