import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { ReactSession } from 'react-client-session';
import { retrieveUserInfo } from '../Utils/RequestUtils';
import { makeRequest } from '../Utils/makeRequest';
import Liked from '../Liked/Liked';
import { deleteAccessToken, retrieveAccessToken, setUserId } from '../Utils/StorageUtils';
import Playlists from '../Playlists/Playlists';
import './MainApp.css'
import { createTheme, ThemeProvider } from '@mui/material';

const MainApp = () => {
    const [name, setName] = useState(null);
    const [accessToken, setAccessToken] = useState();

    const navigate = useNavigate();

    useEffect(() => {
        initialize();
    }, [])

    useEffect(() => {
        if (accessToken) {
            displayUserData();
        }
    }, [accessToken])

    async function initialize() {
        const accessTokenRetrieved = retrieveAccessToken();
        console.log("qacces")

        if (!accessTokenRetrieved || accessTokenRetrieved.length === 0) {
            navigate('/login');
            // console.log("No access token");
        }
        else {
            // console.log("Access token found");
            setAccessToken(accessTokenRetrieved);
        }
    }

    async function displayUserData() {
        const userInfo = await retrieveUserInfo(accessToken);

        if (userInfo) {
            await setUserId(userInfo.id);
            setName(userInfo.display_name);
        }
    }

    function handleLogout() {
        deleteAccessToken();
        navigate('/login');
    }

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
        },
    });

    return (
        <div className='main-container'>
            <ThemeProvider theme={darkTheme}>
                <div>
                    {
                        name &&
                        <div>
                            <div className='flex-row center-div app-header'>
                                <h1>{`Welcome, ${name}`}</h1>
                                <div className='logout-btn button' onClick={handleLogout}>Log Out</div>
                            </div>
                            <div style={{ gap: 20 }} className='sp-cont'>
                                <div>
                                    <Playlists accessToken={accessToken} />
                                </div>
                                <div>
                                    <Liked accessToken={accessToken} />
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </ThemeProvider>

        </div>
    )
}

export default MainApp