import { Avatar, Box, Card, CardActions, CardContent, CardHeader, CardMedia, Chip, Collapse, Divider, IconButton, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import Collapsible from 'react-collapsible';
import { isWithinAWeek } from '../Utils/DateUtils';
import { makeRequest } from '../Utils/makeRequest'
import { addTrackToPlaylist, retrieveLikedSongs, retrievePlaylistItems } from '../Utils/RequestUtils'
import './Playlist.css'
import PlaylistInfoCard from './PlaylistInfoCard';

const Playlist = ({ index, accessToken, playlistData, setPlaylistStartDate }) => {
    const [playlistItems, setPlaylistItems] = useState([]);
    const [newSongs, setNewSongs] = useState([]);
    const [creationTime, setCreationTime] = useState(0);

    useEffect(() => {
        displayPlaylistItems();
        setCreationTime(Math.round((new Date() - playlistData.added_at) / 3600000));
    }, [])

    useEffect(() => {
        getNewlyAddedSongs();
    }, [playlistItems])


    async function getNewlyAddedSongs() {
        const likedSongs = await retrieveLikedSongs(accessToken);

        const newSongs = [];
        //console.log("Checking", playlistData.name, "date", playlistData.added_at)
        for (let i = 0; i < likedSongs.length; i++) {
            const dateAdded = new Date(likedSongs[i].added_at);
            // > playlistDate && <= playlistDate + 7 days
            //console.log(new Date(2022, 3, 2))
            if (isWithinAWeek(playlistData.added_at, dateAdded)) {
                if ((playlistItems && playlistItems.length > 0) && alreadyInPlaylist(likedSongs[i].track, playlistItems)) {
                    //console.log(`${likedSongs[i].track.name} already in list`);
                }
                else {
                    newSongs.push(likedSongs[i]);
                }
                //console.log(`${likedSongs[i].track.name} was added ${(dateAdded - playlistDate) / 1000 / 60 / 60 / 24} days after`);
            }
            else {
                //console.log(playlistDate - weekFrom);
                // console.log(`${likedSongs[i].track.name} was added ${((dateAdded - playlistDate) / 1000 / 60 / 60 / 24)} days before`);
            }
        }

        setNewSongs(newSongs);
    }

    function alreadyInPlaylist(track, playlist) {
        for (let i = 0; i < playlist.length; i++) {
            if (track.id === playlist[i].track.id) {
                return true;
            }
        }

        return false;
    }

    async function displayPlaylistItems(itemsToDisplay) {
        await setPlaylistItems(itemsToDisplay ?? playlistData.tracks);
    }

    async function addNewTracks() {
        const uris = [];

        for (let i = 0; i < newSongs.length; i++) {
            uris.push(newSongs[i].track.uri);
        }

        const result = await addTrackToPlaylist({ uris: uris }, playlistData.id, accessToken);
        const playlistItems = await retrievePlaylistItems(playlistData.id, accessToken);
        await displayPlaylistItems(playlistItems);
    }

    return (
        <div>
            <PlaylistInfoCard playlistData={playlistData} creationTime={creationTime} playlistItems={playlistItems} newSongs={newSongs} addNewTracks={addNewTracks} />
            {/* {
                playlistData.added_at &&
                <div>
                    <div>{`${playlistData.name} ${creationTime >= 0 ? `Created ${creationTime} hours ago` : `Will be created in ${creationTime.toString().substring(1)} hours`}`}</div>
                    <div>{`${playlistData.active}`}</div>
                    <div>
                        Current Tracks:
                        {
                            (playlistItems && playlistItems.length) &&
                            playlistItems.map((el, key) => (
                                <p key={key}>{el.track.name}</p>
                            ))
                        }
                    </div>
                    <div>
                        New Tracks:
                        {
                            (newSongs && newSongs.length) &&
                            <div>
                                <div>
                                    {
                                        newSongs.map((el, key) => (
                                            <p key={key}>{el.track.name}</p>
                                        ))
                                    }
                                </div>
                                <div>
                                    <div className='button' onClick={() => addNewTracks()}>Add Songs to Playlist</div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            } */}
        </div>
    )
}

export default Playlist