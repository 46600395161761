import './App.css';
import React from 'react'
import Login from './Login/Login';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import Callback from './Callback/Callback';
import MainApp from './MainApp/MainApp';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/newtoken" exact element={<Login newToken={true} />} />
          <Route path="/login" exact element={<Login />} />
          <Route path="/callback/*" element={<Callback />} />
          <Route path="/*" exact element={<MainApp />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;


