import React, { useEffect, useState } from 'react'
import Playlist from '../Playlist/Playlist'
import { isWithinAWeek } from '../Utils/DateUtils'
import { makeRequest } from '../Utils/makeRequest'
import { createNewPlaylist, retrievePlaylistItems, retrievePlaylists } from '../Utils/RequestUtils'
import { retrieveUserId } from '../Utils/StorageUtils'

const Playlists = ({ accessToken }) => {
    const [playlists, setPlaylists] = useState([])

    useEffect(() => {
        displayPlaylists();
    }, [])

    async function displayPlaylists() {
        const playlistsRaw = await retrievePlaylists(accessToken);
        const playlistsRetrieved = playlistsRaw.items;

        if (playlistsRetrieved) {
            const filtered = playlistsRetrieved.filter((playlist) => playlist.name.startsWith("#"));
            filtered.sort(function (a, b) {
                return a.name.substring(1) - b.name.substring(1);
            });

            let atleastOneActive = false;

            for (let i = 0; i < filtered.length; i++) {
                const prev = i === 0 ? null : filtered[i - 1];
                const active = await setPlaylistStartDate(filtered[i], prev);
                if (active) {
                    atleastOneActive = true;
                }
            }

            if (!atleastOneActive) {
                console.log(`Create playlist #${filtered.length + 1}`);
                setPlaylists([]);
                await createPlaylist(filtered.length + 1);
                await displayPlaylists();
                return;
            }

            filtered.reverse();

            setPlaylists(filtered);
        }
        else {
            await createPlaylist(1);
            await displayPlaylists();
        }
    }


    async function createPlaylist(playlistNumber) {
        const userId = retrieveUserId();
        const playlistParams = {
            "name": `#${playlistNumber}`,
            "public": "false",
        }

        await createNewPlaylist(userId, playlistParams, accessToken);
    }

    async function setPlaylistStartDate(current, prev) {
        const playlistItems = await retrievePlaylistItems(current.id, accessToken);

        if (prev) {
            let newDate = new Date(prev.added_at);
            newDate.setDate(newDate.getDate() + 7);
            newDate.setHours(0, 0, 0);

            current['added_at'] = newDate;
        }
        else if (playlistItems && playlistItems.length > 0) {
            playlistItems.sort(function (a, b) {
                return new Date(a.added_at) - new Date(b.added_at);
            });

            const timeAdded = new Date(playlistItems[0].added_at);
            current['added_at'] = timeAdded;
        }
        else {
            const today = new Date();
            today.setHours(0, 0, 0);

            current['added_at'] = today;
        }

        const isActive = isWithinAWeek(current.added_at, new Date());
        current['active'] = isActive;
        current['tracks'] = playlistItems;

        return isActive;
    }

    function createIfNeeded(currentPlaylists) {
        const latestPlaylist = currentPlaylists[0];
    }

    return (
        <div>
            {
                playlists &&
                <div>
                    {
                        playlists.map((data, key) => (
                            <Playlist key={key} index={key} accessToken={accessToken} playlistData={data} setPlaylistStartDate={setPlaylistStartDate} />
                        ))
                    }
                </div>
            }
        </div>
    )
}

export default Playlists