import React from 'react'
import { Alert, Avatar, Box, Card, CardActions, CardContent, CardHeader, CardMedia, Chip, Collapse, createTheme, Divider, IconButton, Stack, Switch, ThemeProvider, Typography } from '@mui/material';
import { MdEdit, MdExpandMore } from "react-icons/md";
import { BsMusicNoteBeamed } from "react-icons/bs";
import './Playlist.css'
import Note from './Note';
import Song from '../Song/Song';

const PlaylistInfoCard = ({ playlistData, creationTime, playlistItems, newSongs, addNewTracks }) => {

    const [expanded, setExpanded] = React.useState(false);
    const [newSongsExpanded, setNewSongsExpanded] = React.useState(false);
    const [currentSongsExpanded, setCurrentSongsExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleNewSongsExpandClick = () => {
        setNewSongsExpanded(!newSongsExpanded);
    }

    const handleCurrentSongsExpandClick = () => {
        setCurrentSongsExpanded(!currentSongsExpanded);
    }

    const handleAddSongs = () => {
        setCurrentSongsExpanded(true);
        setNewSongsExpanded(false);
        addNewTracks();
    }

    return (
        <Card className='playlist-cont' sx={{ marginBottom: 2, backgroundColor: '#121212', color: 'white' }}>
            <Box sx={{ p: 1, display: 'flex' }}>
                <Avatar className='playlist-avatar' variant="rounded" src={playlistData.images[playlistData.images.length - 1]?.url} />
                <Stack className='playlist-stack' spacing={0.5}>
                    <div>
                        {playlistData.name}
                        <IconButton sx={{ height: 30, width: 30 }}>
                            <MdEdit style={{ color: 'white' }} />
                        </IconButton>
                    </div>
                    <div className='playlist-subtext'>{`${playlistData.added_at.toLocaleDateString()} ${creationTime <= 0 ? ` - Will be created in ${creationTime.toString().substring(1)} hours.` : ''}`}</div>
                </Stack>

            </Box>
            <Divider />
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ px: 2, py: 1, backgroundColor: '#181818' }}
            >
                <Chip sx={{ color: `${playlistData.active ? 'lime' : 'red'}`, backgroundColor: `${playlistData.active ? '#237c1e' : '#741a1a'}` }} label={`${playlistData.active ? 'Active' : 'Inactive'}`} />
                <IconButton onClick={handleExpandClick}>
                    <MdExpandMore style={{ color: 'white' }} />
                </IconButton>
            </Stack>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <Card>
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <Alert sx={{ marginBottom: 1, width: 1 }} icon={<BsMusicNoteBeamed />} severity='success'>
                                Current Songs
                            </Alert>
                            <IconButton style={{ position: 'absolute', right: 15, top: 12 }} sx={{ p: 0 }} onClick={handleCurrentSongsExpandClick}>
                                <MdExpandMore style={{ color: 'white' }} />
                            </IconButton>
                        </div>
                        <Collapse in={currentSongsExpanded} timeout="auto" unmountOnExit>
                            {
                                (playlistItems && playlistItems.length) ?
                                    <div className='pic-cont'>
                                        {
                                            playlistItems.map((el, key) => (
                                                <Song id={key} songData={el} />
                                            ))
                                        }
                                    </div>
                                    : <div>No songs</div>
                            }
                        </Collapse>
                    </Card>
                    <Card>
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <Alert sx={{ marginBottom: 1, width: 1 }} severity='info'>
                                New Songs
                            </Alert>
                            <IconButton style={{ position: 'absolute', right: 15, top: 12 }} sx={{ p: 0 }} onClick={handleNewSongsExpandClick}>
                                <MdExpandMore style={{ color: 'white' }} />
                            </IconButton>
                        </div>
                        <Collapse in={newSongsExpanded} timeout="auto" unmountOnExit>
                            {
                                (newSongs && newSongs.length) ?
                                    <div>
                                        <div className='pic-cont'>
                                            {
                                                newSongs.map((el, key) => (
                                                    <Song id={key} songData={el} />
                                                ))
                                            }
                                        </div>
                                        <div>
                                            <div className='add-songs-btn button' onClick={handleAddSongs}>Add Songs to Playlist</div>
                                        </div>
                                    </div>
                                    :
                                    <div>No new songs</div>
                            }
                        </Collapse>
                    </Card>
                </CardContent>
            </Collapse>
        </Card>
    )
}

export default PlaylistInfoCard