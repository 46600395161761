import axios from "axios";
import { handleError } from "./ErrorHandler";
import { apiUrl } from "./RequestUtils";

export async function makeRequest(requestUrl: string, accessToken: string) {
    var results;
    var url = apiUrl + requestUrl;
    const config = {
        headers: { Authorization: `Bearer ${accessToken}` }
    };

    await axios.get(url, config)
        .then(response => {
            results = response;
        })
        .catch(error => {
            // with error
            handleError(error);
        })

    return results;
}

export async function makePostRequest(requestUrl: string, accessToken: string, dataObj?: any) {
    var results;
    var url = apiUrl + requestUrl;
    const headers = {
        Authorization: `Bearer ${accessToken}`
    };

    await axios.post(url, (dataObj ?? { headers }), { headers })
        .then(response => {
            results = response;
        })
        .catch(error => {
            // with error
            console.log(error);
        })

    return results;
}
