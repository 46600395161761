import React, { useEffect } from 'react'
import { ReactSession } from 'react-client-session';
import { useNavigate } from "react-router-dom";
import { FaSpotify } from "react-icons/fa";
import './Login.css'

const Login = ({ newToken }) => {
    const navigate = useNavigate();
    const scopes = ['user-library-read', 'playlist-read-private', 'playlist-modify-public', 'playlist-modify-private'];

    useEffect(() => {
        ReactSession.setStoreType("localStorage");

        const accessToken = ReactSession.get('access_token');

        if (accessToken && accessToken.length > 0) {
            navigate('/');
            // console.log("Access token found");
        }

    }, [])

    function login() {
        const url = `https://accounts.spotify.com/en/authorize?
client_id=33e2751930934002b9a45765ea399105
&redirect_uri=https%3A%2F%2Fspotify.vbi.dev%2Fcallback%2F
&scope=${encodeURIComponent(scopes.join(' '))}
&response_type=token`;

        window.location.href = url;
    }

    return (
        <div className='login-container'>
            <div className='login-form'>
                <div>
                    <div>{newToken && 'Access Token Expired, please login again.'}</div>
                    <div className='login-text'>Login via Spotify</div>
                </div>

                <div className='button' onClick={login}>
                    <FaSpotify />
                    LOGIN
                </div>
            </div>
        </div>
    )
}

export default Login