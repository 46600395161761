import React, { useEffect } from 'react'
import { ReactSession } from 'react-client-session';
import { useNavigate } from "react-router-dom";
import { setAccessToken } from '../Utils/StorageUtils';

const Callback = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const urlHash = window.location.hash.substring(1);
        const urlSearchParams = new URLSearchParams(urlHash);
        const access_token = urlSearchParams.get('access_token');

        if (!access_token || access_token.length === 0) {
            console.log('Something went wrong, no access token could be found in', window.location.href);
        }
        else {
            setAccessToken(access_token);
            navigate('/');
        }

    }, [])


    return (
        <div>Callback</div>
    )
}

export default Callback