import { Avatar, Card, IconButton, Stack } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { Textfit } from 'react-textfit'
import './Song.css'

const Song = ({ songData }) => {
    const artists = songData.track.artists

    return (
        <Card className='song-cont' sx={{ marginBottom: 2, backgroundColor: '#121212', color: 'white' }}>
            <Box sx={{ p: 0, display: 'flex' }}>
                <Avatar className='playlist-avatar' variant="rounded" src={songData.track.album.images[2].url} />
                <Stack className='song-stack' spacing={0.5}>
                    <div className='song-name'>
                        {songData.track.name}
                    </div>
                    <div className='song-subtext'>
                        {songData.track.artists.map(artist => artist.name).join(', ')}
                    </div>
                </Stack>
            </Box>
        </Card>
    )
}

export default Song